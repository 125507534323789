import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";
import { faMaparker } from "@fortawesome/fontawesome-free-solid";


export default function WheretoBuy() {
  const [lang, setLang] = useState(0);
  const [lat, setLat] = useState(0);
  const navigate = useNavigate();
  const isMountedRef = useRef(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      const geocoder = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_WHERE_TO_BUY_TOKEN,
        types: "postcode,address",
        placeholder: "Enter Location",
        countries: "us",
      });
      geocoder.addTo("#location");
      geocoder.on("result", (e) => {
        setLang(e.result.center[0]);
        setLat(e.result.center[1]);
      });
    }
  }, []);

  const handleSearch = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "customEvent",
      eventCategory: "WTB_Find_Near_Me",
      eventAction: "Find now",
      eventLabel: "IW Harper",
      dimension14: "IW Harper",
      eventInteraction: true,
    });
    let redirect_url = "/where-to-buy/find-near-me/";
    if (lang && lat) {
      navigate(redirect_url + "?lat=" + lat + "&lng=" + lang);
    } else {
      navigate(redirect_url);
    }
  };

  const CallShopOnline = () => {
    navigate("/where-to-buy/shop-online");
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Where to Buy</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
          <link
            rel="stylesheet"
            href="https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.css"
            type="text/css"
          />
          <section className="content page-where-to-buy">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="where-to-buy-Headline text-center">
                    <h1>
                      <span className="text-danger">W</span>here To Buy
                    </h1>
                  </div>
                </Col>
              </Row>
              <div className="whereDiv">
                <div md={6} xs={12} className="find-section">
                  <div className="inner">
                    <div className="heading">
                      <h2>Find Near Me</h2>
                    </div>
                    <div className="icon">
                      <FontAwesomeIcon icon={["fas", "fa-map-marker-alt"]} />
                    </div>
                    <div className="copy">
                      <p>
                        Use our store locator to find what’s available at a
                        retailer or restaurant/bar near you
                      </p>
                    </div>
                    <div className="formWrapper">
                      <div id="location"></div>
                      <Button
                        className="btn red_btn"
                        onClick={handleSearch}
                        id="wtb-search"
                        type="submit"
                      >
                        Find Now
                      </Button>
                    </div>
                  </div>
                </div>
                <div md={6} xs={12} className="shop-online">
                  <div className="inner">
                    <div className="heading">
                      <h2>Shop Online</h2>
                    </div>

                    <div className="icon">
                      <FontAwesomeIcon icon={["fas", "fa-shopping-cart"]} />
                    </div>
                    <div className="copy p-3">
                      <p></p>
                    </div>
                    <div className="linkWrapper">
                      <Button
                        className="btn red_btn"
                        onClick={CallShopOnline}
                        type="submit"
                      >
                        Shop Products
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
